import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {

  //创建和编辑页面
  getProductList: "/api/ProductService/Product/GetListPaged",
  updateProduct: "/api/ProductService/Product/Update",
  createProduct: "/api/ProductService/Product/Create",
  getPackingVerify: "/api/ProductService/PackingVerify/Get",

  //存储仓列表
  getTransitWarehouseList:
    "/api/WarehouseService/InventoryStatistics/GetUserStoragePaged",
  //代发仓列表
  getReplaceSendWarehouseList:
    "/api/WarehouseService/InventoryStatistics/GetUserDeliveryPaged",
  getProductInventoryTotal: "/api/WarehouseService/InventoryStatistics/GetInventoryTotal",
  getProducInfo: "/api/ProductService/Product/Get",
  getDefaultProductSeLable:"/api/ProductService/Label/GetDefault",
  joinDownloadTemplateUrl:"/api/ProductService/Product/GetImportTemplate",

  parseFile:"/api/ProductService/Product/ParseExcelData",
  importProduct:"/api/ProductService/Product/BatchImport",
  deleteProduct:"/api/ProductService/Product/Hide",
   // 导出产品excel
   exportInventoryList: "/api/ProductService/Product/ExportProduct",
  // 导入产品标签
  createProductLabel: "/api/ProductService/Label/Create",
};

export const exportInventoryList = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.exportInventoryList);
};

export const getProductList = (data) => {
  return client.request({
    url: Api.getProductList,
    data: data,
    method: "post",
  });
};

export const getTransitWarehouseList = (data) => {
  return client.request({
    url: Api.getTransitWarehouseList,
    data: data,
    method: "post",
  });
};

export const getReplaceSendWarehouseList = (data) => {
  return client.request({
    url: Api.getReplaceSendWarehouseList,
    data: data,
    method: "post",
  });
};

export const getProductInventoryTotal = (data) => {
  return client.request({
    url: Api.getProductInventoryTotal,
    data: data,
    method: "post",
  });
};

export const getProducInfo = (data) => {
  return client.request({
    url: Api.getProducInfo,
    params: data,
    method: "get",
  });
};

export const createProduct = (data) => {
  return client.request({
    url: Api.createProduct,
    data: data,
    method: "post",
  });
};

export const updateProduct = (data) => {
  return client.request({
    url: Api.updateProduct,
    data: data,
    method: "post",
  });
};

export const getPackingVerify = (data) => {
  return client.request({
    url: Api.getPackingVerify,
    params: data,
    method: "get",
  });
};

export const getDefaultProductSeLable = (productId) => {
  return client.request({
    url: Api.getDefaultProductSeLable,
    params: {productId},
    method: "get",
  });
};

export const joinDownloadTemplateUrl = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.joinDownloadTemplateUrl);
  return url;
};


export const parseFile = (data) => {
  return client.request({
    url: Api.parseFile,
    data: data,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const importProduct = (data) => {
  return client.request({
    url: Api.importProduct,
    data: data,
    method: "post",
  });
};

export const deleteProduct = (id) => {
  return client.request({
    url: Api.deleteProduct,
    data: {id},
    method: "post",
  });
};

export const createProductLabel = (data) => {
  return client.request({
    url: Api.createProductLabel,
    data,
    method: "POST",
  });
};


