<template>
  <div class="address ">
    <a-form ref="refForm"
            :rules="rules"
            :model="data">
      <a-row>
        <a-col :span="24"
               :md="{ span: 24 }"
               :xl="{ span: 8 }"
               :xxl="{ span: 6 }">
          <a-form-item v-if="isShowSelectCommonAddress"
                       :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 6},xxl: {span: 6},}"
                       :label="$t('warehouse.common_address')">
            <a-select v-model:value="data.selectedCommonAddress"
                      :loading="dropdownData.commonAddressLoading"
                      @change="changeSelectCommonAddress"
                     
                      optionFilterProp="search-key"
                      show-search
                      :allowClear="true"
                      :placeholder="$t('warehouse.selected_common_address')">
              <a-select-option v-for="item in dropdownData.commonAddress"
                               :key="item.id"
                               :value="item.id"
                               :countryId="item.countryId"
                               :provinceId="item.provinceId"
                               :cityId="item.cityId"
                               :countyId="item.countyId"
                               :address="item.address"
                               :address2="item.address2"
                               :postCode="item.postCode"
                               :telephoneCountryId="item.telephoneCountryId"
                               :telephone="item.telephone"
                               :commonAddressName="item.contactName"
                               :search-key="item.contactName"
                               :title="item.contactName">
                {{item.contactName}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col v-if="isShowSelectCommonAddress&&isShowOriginate"
               :span="24"
               :md="{ span: 24 }"
               :xl="{ span: 8 }"
               :xxl="{ span: 6 }">
          <a-form-item :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 6},xxl: {span: 6},}"
                       name="commonAddressName">
            <template #label>
              <span v-if="isShipAddress">{{$t('warehouse.originate')}}</span>
              <span v-else>{{$t('warehouse.destination')}}</span>
            </template>
            <a-input v-model:value="data.commonAddressName" v-user-name-filter />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24"
               :xxl="12">
          <a-form-item :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 2},xxl: {span: 3},}"
                       :wrapper-col="{sm: {span: 20},md: {span: 21},xl: {span: 22},xxl: {span: 21},}"
                       name="sendGoodsAddress"
                       ref="refSendGoodsAddress"
                       :autoLink="false">
            <template #label>
              <span v-if="isShipAddress">{{$t('warehouse.from_product_address')}}</span>
              <span v-else>{{$t('warehouse.to_product_address')}}</span>
            </template>
            <a-select v-model:value="data.selectedCountrys"
                      :allowClear="true"
                      show-search
                      optionFilterProp="search-key"
                      :loading="dropdownData.countryLoading"
                      @change="handleChangeCountry"
                      class="mr-2 mb-1"
                      style="max-width:150px;"
                      :placeholder="$t('common.country')">
              <a-select-option v-for="item in dropdownData.countrys"
                               :key="item.id"
                               :title="getLanguageName(item)+'('+item.ioS2+')'"
                               :search-key="item.cnName + item.enName+item.ioS2">
                {{getLanguageName(item)}}({{item.ioS2}})
              </a-select-option>
            </a-select>

            <a-select v-model:value="data.selectedProvinces"
                      @change="handleChangeProvinces"
                      name="selectedProvinces"
                      class="mr-2 mb-1 "
                      style="max-width:150px;"
                      :loading="dropdownData.provincesLoading"
                      :allowClear="true"
                      show-search
                      optionFilterProp="search-key"
                      :placeholder="$t('common.province')">
              <a-select-option v-for="item in dropdownData.provinces"
                               :title="getLanguageName(item)+item.abbrCode"
                               :key="item.id"
                               :search-key="item.cnName + item.enName+item.abbrCode">
                {{getLanguageName(item) + (item.abbrCode?"("+item.abbrCode+")":"")}}
              </a-select-option>
            </a-select>

            <a-select v-model:value="data.selectedCity"
                      @change="handleChangeCity"
                      class="mr-2 mb-1 "
                      style="max-width:150px;"
                      :allowClear="true"
                      show-search
                      :loading="dropdownData.cityLoading"
                      optionFilterProp="search-key"
                      :placeholder="$t('common.city')">
              <a-select-option v-for="item in dropdownData.citys"
                               :title="getLanguageName(item)"
                               :key="item.id"
                               :search-key="item.cnName + item.enName">
                {{getLanguageName(item)}}
              </a-select-option>
            </a-select>

            <a-select v-if="dropdownData.countys.length > 0 && data.selectedCity"
                      v-model:value="data.selectedCounty"
                      class="mr-2 "
                      style="max-width:150px;"
                      :allowClear="true"
                      show-search
                      :loading="dropdownData.countyLoading"
                      optionFilterProp="search-key"
                      :placeholder="$t('common.county')">
              <a-select-option v-for="item in dropdownData.countys"
                               :title="getLanguageName(item)"
                               :key="item.id"
                               :search-key="item.cnName + item.enName">
                {{getLanguageName(item)}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24"
               :md="{ span: 24 }"
               :xl="{ span: 8 }"
               :xxl="{ span: 6 }">
          <a-form-item name="detailedAddress"
                       :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 6},xxl: {span: 6},}"
                       :label="$t('warehouse.detail_address')">
            <a-input v-model:value="data.detailedAddress"
                     v-address-filter
                     :maxlength="35"
                      />
          </a-form-item>
        </a-col>
        <a-col :span="24"
               :sm="{ span: 24 }"
               :xl="{ span: 8 }"
               :xxl="{ span: 6 }">
          <a-form-item name="detailedAddress2"
                       :label-col="{sm: {span: 4},md: {span: 3},xl: {span: 6},xxl: {span: 6},}"
                       :label="$t('warehouse.detail_address')+2">
            <a-input v-model:value="data.detailedAddress2"
                     v-address-filter
                     :maxlength="35"
                      />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24"
               :md="{ span: 24 }"
               :xl="{ span: 8 }"
               :xxl="{ span: 6 }">
          <a-form-item :label="$t('common.postcode')"
                       :label-col="{sm: {span: 4},md: {span: 3}, xl: {span: 6},xxl: {span: 6},}"
                       name="postCode">
            <a-input v-model:value="data.postCode"
                      />
          </a-form-item>
        </a-col>
        <a-col :sm="{ span: 20, offset: 4}"
               :md="{ span: 21, offset: 3}"
               :xl="{ span: 8, offset: 1 }"
               :xxl="{ span: 6, offset: 1 }">
          <a-row>
            <a-col :span="24"
                   :xl="{ span: 21, offset: 3 }"
                   :xxl="{ span: 22, offset: 2 }">
              <a-form-item>
                <a-button :loading="postCodeQueryData.loading"
                          @click="handlePostCodeQuery"
                          size="small">
                  {{$t('waybill.check_the_postcode')}}
                </a-button>
                <CPostQuery ref="postCodeQueryRef"
                            v-model:visible="postCodeQueryData.visible"
                            :postCode="data.postCode"
                            @onSelected="handleOnPostQuerySelected" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>
      </a-row>

      <a-row v-if="isShowPhone">
        <a-col :span="24"
               :md="{ span: 24 }"
               :xl="{ span: 8 }"
               :xxl="{ span: 6 }">
          <a-form-item :label="$t('warehouse.phone')"
                       :name="'telephone'"
                       :label-col="{sm: {span: 4},md: {span: 3}, xl: {span: 6},xxl: {span: 6},}"
                       >

                <a-input-group style="display: flex" compact>
                  <a-select
                    style="width: 100px"
                    v-model:value="data.telephoneCountryId"
                    :placeholder="$t('logistics.area_code')"
                    :loading="dropdownData.phoneCountryLoading"
                    option-label-prop="label"
                    optionFilterProp="search-key"
                    show-search
                    @change="handleChangePhoneCountryCode"
                  >
                    <a-select-option
                      v-for="(item, index) in dropdownData.phoneCountryList"
                      :key="index"
                      :value="item.id"
                      :label="`+${item.telCode}`"
                      :code="item.telCode"
                      :search-key="
                        item.ioS2 +
                        item.cnName +
                        item.enName +
                        '+' +
                        item.telCode
                      "
                      :title="`+${item.telCode}(${getLanguageName(item)})`"
                      >+{{ item.telCode }}({{
                        getLanguageName(item)
                      }})</a-select-option
                    >
                  </a-select>
                  <a-input
                    style="flex: 1"
                    v-model:value="data.telephone"
                    :placeholder="$t('warehouse.phone')"
                    @change="handleChangePhone"
                  />
                </a-input-group>

                     
          </a-form-item>
        </a-col>
      
      </a-row>
    </a-form>
  </div>

  <div>
    <a-row>
      <a-col :sm="{ span: 20, offset: 4 }"
             :md="{ span: 21, offset: 3 }"
             :xl="{ span: 8, offset: 1 }"
             :xxl="{ span: 6, offset: 1 }"
              v-if="isShowButtonSaveCommon&&!data.selectedCommonAddress"
              >
        <a-row>
          <a-col :span="24"
                 :xl="{ span: 23, offset: 3 }"
                 :xxl="{ span: 22, offset: 2 }">
            <a-button type="primary"
                      class="mr-5 mb-2"
                      size="small"
                      @click="handleSaveCommonAddress"
                      :loading="dropdownData.buttonSaveCommonAddressLoading"
                     
                      ghost>{{$t('warehouse.save_as_common_address')}}</a-button>
          </a-col>
        </a-row>
      </a-col>
      <a-col :sm="{ span: 20, offset: 4 }"
             :md="{ span: 21, offset: 3 }"
             :xl="{ span: 8, offset: 1 }"
             :xxl="{ span: 6, offset: 1 }">
        <a-row>
          <a-col :span="24"
                 :xl="{ span: 23, offset: 3 }"
                 :xxl="{ span: 22, offset: 2 }">
            <a-button type="primary"
                      class="mb-2"
                      size="small"
                      :loading="dropdownData.buttonSaveCommonAddressLoading"
                      @click="handleSaveCommonAddress"
                      v-show="isShowButtonSaveCommon&&data.selectedCommonAddress"
                      ghost>{{$t('warehouse.refresh_address')}}</a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

  </div>

</template>

<script>
import { reactive, onMounted, ref, watch } from "vue";
import {
  Row, Col, Input,
  Select, Button, Form, Modal, message
} from "ant-design-vue";
import CPostQuery from "@/views/components/CPostQuery.vue";
import { useI18n } from "vue-i18n/index";
import {
  getCommonAddress, createCommonAddress, updateCommonAddress,
  getGlobalCountrys,
} from "../../api/modules/common/index";
import { getName,verifyMobilePhoneNumber,replaceBrackets } from "../../utils/general";
import { useStore } from "vuex";
import { useNewAddrHelper } from "../../utils/addressHelper"

export default ({
  name: "Address",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    CPostQuery,
  },
  props: {
    isShowButtonSaveCommon: {
      type: Boolean,
      default: true,
      required: false
    },
    isShowSelectCommonAddress: {
      type: Boolean,
      default: false,
      required: false
    },
    isShowOriginate: {
      type: Boolean,
      default: true,
      required: false
    },
    isShipAddress: {//true:文字是发货,false:文字收货
      type: Boolean,
      default: true,
      required: false
    },
    isShowPhone: {//是否显示电话和区号
      type: Boolean,
      default: false,
      required: false
    },
    addressData: {
      type: Object,
      default: () => ({
        inputAddressAlias: "",
        selectedCommonAddress: null,
        commonAddressName: "",//发自\发至
        selectedCountrys: null,
        selectedProvinces: null,
        selectedCity: null,
        selectedCounty: null,
        detailedAddress: "",
        detailedAddress2: "",
        postCode: ""
      }),
      required: false
    },
    cacheObj:{
      type: Object,
      default: () => ({}),
      required: false
    }
  },
  setup (props, { emit }) {

    const refInputCommonAddress = ref();
    const refForm = ref();
    const refSendGoodsAddress = ref();
    const postCodeQueryRef = ref(null);
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const addrHelper = useNewAddrHelper(false, props.cacheObj);

    const dropdownData = reactive({
      buttonSaveCommonAddressLoading: false,
      commonAddress: [],
      commonAddressLoading: false,
      countrys: [],
      countryLoading: false,
      provinces: [],
      provincesLoading: false,
      citys: [],
      cityLoading: false,
      countys: [],
      countyLoading: false,

      phoneCountryList:[],
      phoneCountryLoading:false
    })

    const data = reactive({});

    const postCodeQueryData = reactive({
      visible: false,
      loading: false,
    });

    watch(() => props.addressData, (newV, oldV) => {
      Object.assign(data, newV || {});
    }, {
      immediate: true, deep: true
    });

    watch(() => {
      return [
        props.addressData?.selectedCountrys,
        props.addressData?.selectedProvinces,
        props.addressData?.selectedCity
      ].join("-")
    }, (newV, oldV) => {
      funcGetProvinces();
    })

    const handlePostCodeQuery = () => {
      postCodeQueryData.visible = true;
      if (postCodeQueryRef && postCodeQueryRef.value && postCodeQueryRef.value.handleQuery) {
        postCodeQueryRef.value.handleQuery();
      }
    }

    const handleOnPostQuerySelected = (record) => {
      if (record) {
        data.selectedCountrys = record.countryId;
        data.selectedProvinces = record.provinceId;
        data.selectedCity = record.cityId;
        data.selectedCounty = record.countyId;
        if (record.address) {
          data.detailedAddress = record.address;
        }
        data.postCode = record.postCode;

        funcGetCountrys();
      }
    }

    const handleSaveCommonAddress = () => {
      refForm.value
        .validate()
        .then(() => {
          let par = {
            "id": data.selectedCommonAddress,
            "contactName": data.commonAddressName,//发自
            "countryId": data.selectedCountrys,
            "provinceId": data.selectedProvinces,
            "cityId": data.selectedCity,
            "countyId": data.selectedCounty,
            "address": data.detailedAddress,
            "address2": data.detailedAddress2,
            "postCode": data.postCode,
            "telephoneCountryId": data.telephoneCountryId,
            "telephone": data.telephone,
            "containerTelephone": props.isShowPhone,
          }

          dropdownData.buttonSaveCommonAddressLoading = true;
          if (par.id) {
            return updateCommonAddress(par).then(() => {
              message.success(vueI18n.t("common.succeed"));
              hanldeGetCommonAddress();
            })
          } else {
            return createCommonAddress(par).then((res) => {
              message.success(vueI18n.t("common.succeed"));
              hanldeGetCommonAddress().then(() => {
                data.selectedCommonAddress = res.result;
              });
            })
          }
        })
        .catch(error => {
          dropdownData.buttonSaveCommonAddressLoading = false;
        }).finally(() => {
          dropdownData.buttonSaveCommonAddressLoading = false;
        });;
    };

    const hanldeGetCommonAddress = async () => {
      dropdownData.commonAddressLoading = true;
      getCommonAddress().then(({ result }) => {
        if (result) {
          dropdownData.commonAddress = result;
        }
        dropdownData.commonAddressLoading = false;
      }).catch(() => {
        dropdownData.commonAddressLoading = false;
      })
    }

    const changeSelectCommonAddress = (value, option) => {
      if (!value) {
        return
      }
      data.commonAddressName = option.commonAddressName ?? "";
      data.selectedCountrys = option.countryId ?? "";
      data.selectedProvinces = option.provinceId ?? "";
      data.selectedCity = option.cityId ?? "";
      data.selectedCounty = option.countyId ?? "";
      handleChangeCountry();

      data.detailedAddress = option.address ?? "";
      data.detailedAddress2 = option.address2 ?? "";
      data.postCode = option.postCode ?? "";

      data.telephoneCountryId = option.telephoneCountryId ?? null;
      data.telephone = option.telephone ?? null;


      refForm.value?.clearValidate();
    }

    const handleChangeCountry = async () => {
      funcGetProvinces();
    }

    const funcGetCountrys = async () => {
      dropdownData.countryLoading = true;
      addrHelper.getCountries()
        .then(res => {
          dropdownData.countrys = res;
        }).finally(() => {
          dropdownData.countryLoading = false;
          if (data.selectedCountrys
            && dropdownData.countrys.findIndex(x => x.id == data.selectedCountrys) == -1
          ) {
            data.selectedCountrys = null;
          }
          funcGetProvinces();
        });
    }

    const funcGetProvinces = () => {
      dropdownData.provincesLoading = true;
      addrHelper.getProvinces(data.selectedCountrys)
        .then(res => {
          dropdownData.provinces = res;
        }).finally(() => {
          dropdownData.provincesLoading = false;
          if (data.selectedProvinces
            && dropdownData.provinces.findIndex(x => x.id == data.selectedProvinces) == -1
          ) {
            data.selectedProvinces = null;
          }
          funcGetCitys();
        });
    }

    const handleChangeProvinces = async () => {
      funcGetCitys();
    }

    const funcGetCitys = async () => {
      dropdownData.cityLoading = true;
      await addrHelper.getCities(data.selectedProvinces)
        .then(res => {
          dropdownData.citys = res ?? [];
        }).finally(() => {
          dropdownData.cityLoading = false;
          if (data.selectedCity
            && dropdownData.citys.findIndex(x => x.id == data.selectedCity) == -1
          ) {
            data.selectedCity = null;
          }
          funcGetCountys();
        });
    }

    const handleChangeCity = async () => {
      funcGetCountys();

      //解决先验证了,然后选完地址后还未清除错误消息的情况,只验证到城市
      if (
        data.selectedProvinces
        &&
        (dropdownData.citys.length == 0 || data.selectedCity)
      ) {
        refForm.value?.clearValidate('sendGoodsAddress');
      }
    }

    const funcGetCountys = async () => {
      dropdownData.countyLoading = true;
      await addrHelper.getCounties(data.selectedCity)
        .then(res => {
          dropdownData.countys = res ?? [];
        }).finally(() => {
          dropdownData.countyLoading = false;
          if (data.selectedCounty
            && dropdownData.countys.findIndex(x => x.id == data.selectedCounty) == -1
          ) {
            data.selectedCounty = null;
          }
        });
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    let validateAddress = async (rule, value) => {
      //区县地址不必填了
      // if (dropdownData.countys.length > 0 && !data.selectedCounty) {
      //   return Promise.reject();
      // }

      if (dropdownData.citys?.length > 0 && !data.selectedCity) {
        return Promise.reject();
      }

      if (dropdownData.provinces?.length > 0 && !data.selectedProvinces) {
        return Promise.reject();
      }

      if (!data.selectedCountrys) {
        return Promise.reject();
      }
      return Promise.resolve();
    };

    //获得地址信息(包括id,中英文),用于组合展示.
    const getAddressInfo = () => {
      let address = {
        countryId: null,
        countryCnName: null,
        countryEnName: null,
        provinceId: null,
        provinceCnName: null,
        provinceEnName: null,
        abbrCode: null,
        cityId: null,
        cityCnName: null,
        cityEnName: null,
        countyId: null,
        countyCnName: null,
        countyEnName: null,
      };
      address.address = data.detailedAddress;
      address.address2 = data.detailedAddress2;
      address.postCode = data.postCode;
      address.name = data.commonAddressName;
      address.telephoneCountryId = data.telephoneCountryId;
      address.telephoneCountryCode = dropdownData.phoneCountryList?.find(x=>x.id == address.telephoneCountryId)?.telCode;
      address.telephone = data.telephone;
      if (data.selectedCountrys && dropdownData.countrys) {
        let country = dropdownData.countrys.find(x => x.id == data.selectedCountrys);
        address.countryId = country.id;
        address.countryCnName = country.cnName;
        address.countryEnName = country.enName;

        if (data.selectedProvinces && dropdownData.provinces) {
          let province = dropdownData.provinces.find(x => x.id == data.selectedProvinces);
          address.provinceId = province.id;
          address.provinceCnName = province.cnName;
          address.provinceEnName = province.enName;
          address.abbrCode = province.abbrCode;
          if (data.selectedCity && dropdownData.citys) {
            let city = dropdownData.citys.find(x => x.id == data.selectedCity);
            address.cityId = city.id;
            address.cityCnName = city.cnName;
            address.cityEnName = city.enName;
            if (data.selectedCounty && dropdownData.countys) {
              let county = dropdownData.countys.find(x => x.id == data.selectedCounty);
              address.countyId = county.id;
              address.countyCnName = county.cnName;
              address.countyEnName = county.enName;
            }
          }
        }
      }
      Object.assign(data, address);
      return data;
    }
    
    //---------------------电话相关 start------------------------------------
     //电话的国家区号
    const getCountryTelephoneAreaCode = () => {
      dropdownData.phoneCountryLoading = true;
      getGlobalCountrys()
        .then(({ result }) => {
          dropdownData.phoneCountryList = result;
          dropdownData.phoneCountryLoading = false;
        })
        .catch(() => {
          dropdownData.phoneCountryLoading = false;
        });
    };

     const handleChangePhoneCountryCode = (record, expand) => {
      data.phoneCountryCode = expand?.code ?? null;
      data.telephoneCountryTelCode = expand?.code ?? null; //这行代码预防bug出现
      handleChangePhone();
    };

    const handleChangePhone = () => {
      refForm.value
        .validateFields([ "telephone"])
        .then(() => {
          data.telephone = replaceBrackets(data.telephone);
        })
        .catch(() => {});
    };
    //---------------------电话相关 end------------------------------------

    const rules = {
      sendGoodsAddress: [{
        required: true,
        validator: validateAddress,
        whitespace: true,
        trigger: ['change', 'blur'],
        message: () => {
          // return vueI18n.t("common.p0_is_not_complete", [vueI18n.t("warehouse.send_goods_address")])
          return vueI18n.t("common.p0_is_not_complete", [vueI18n.t("warehouse.address")])
        }
      }],
      detailedAddress: [{
        required: true,
        whitespace: true,
        trigger: ['change', 'blur'],
        validator: (rule, value) => {
          if (!value) {
            return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.detail_address")]));
          }
          if (value && value?.length > 35) {
            return Promise.reject(vueI18n.t("common.the_input_length_is_too_long"));
          }

          return Promise.resolve();
        },
      }],
      detailedAddress2: [{
        required: false,
        trigger: ['change', 'blur'],
        validator: (rule, value) => {
          if (value && value?.length > 35) {
            return Promise.reject(vueI18n.t("common.the_input_length_is_too_long"));
          }
          return Promise.resolve();
        },
      }],
      postCode: [{
        required: true,
        whitespace: true,
        trigger: ['change', 'blur'],
        validator: (rule, value) => {
          if (!value||value.trim().length == 0) {
            return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("common.postcode")]));
          }

          if (value && value.trim().length < 5) {
            return Promise.reject(vueI18n.t("common.p0_please_enter_at_least_p1_digits", [vueI18n.t("common.postcode"),5]));
          }
          return Promise.resolve();
        },
      }],
      inputAddressAlias: [{
        required: true,
        whitespace: true,
        trigger: ['change', 'blur'],
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.alias")])
        }
      }],
      commonAddressName: [{
        required: true,
        whitespace: true,
        trigger: ['change', 'blur'],
        message: () => {
          if (props.isShipAddress) {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.originate")])
          } else {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.destination")])
          }
        }
      }, {
        trigger: ['change', 'blur'],
        pattern: /^.{1,32}$/,
        message: () => {
          if (props.isShipAddress) {
            return vueI18n.t("common.p0_up_to_p1_characters", [vueI18n.t("warehouse.originate"), 32])
          } else {
            return vueI18n.t("common.p0_up_to_p1_characters", [vueI18n.t("warehouse.destination"), 32])
          }
        }
      }],
       telephone: [
          {
            required: true,
            whitespace: true,
            trigger: ["change", "blur"],
            validator: (rule, value) => {
              if (!data.telephoneCountryId) {
                return Promise.reject(
                  vueI18n.t("common.p0_is_required", [
                    vueI18n.t("logistics.area_code"),
                  ])
                );
              }
              if (!value) {
                return Promise.reject(
                  vueI18n.t("common.p0_is_required", [
                    vueI18n.t("warehouse.phone"),
                  ])
                );
              }
              let selectCountry = dropdownData.phoneCountryList.find(
                (item) => item.id === data.telephoneCountryId
              );
              let countryIos2Code = selectCountry?.ioS2 ?? null;
              if (
                verifyMobilePhoneNumber(
                  countryIos2Code,
                  (selectCountry?.telCode ?? "") + value
                )
              ) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  vueI18n.t("common.p0_incorrect_format", [
                    vueI18n.t("warehouse.phone"),
                  ])
                );
              }
            },
          },
        ],
    };

    onMounted(async () => {
      if (props.isShowSelectCommonAddress) {
        hanldeGetCommonAddress();
      }
      funcGetCountrys();

      if (props.isShowPhone) {
        getCountryTelephoneAreaCode();
      }

    });

    return {
      refForm,
      refInputCommonAddress,
      refSendGoodsAddress,
      dropdownData,
      data,
      rules,
      props,
      postCodeQueryRef,
      postCodeQueryData,

      handleChangeCountry,
      handleChangeProvinces,
      handleChangeCity,
      getLanguageName,
      changeSelectCommonAddress,
      handleSaveCommonAddress,
      funcGetProvinces,
      funcGetCitys,
      funcGetCountys,
      getAddressInfo,
      handlePostCodeQuery,
      handleOnPostQuerySelected,

      handleChangePhoneCountryCode,
      handleChangePhone,
    }
  }
})
</script>
<style lang="less" scoped>
</style>